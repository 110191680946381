<template>
  <BaseListPage
    locale-section="pages.herSwedenParish"
    route="herSwedenParishDetail"
  >
    <HerSwedenParishList />
  </BaseListPage>
</template>

<script>
export default {
  name: "HerSwedenParishPage",
  components: {
    BaseListPage: () => import("@/components/BaseListPage"),
    HerSwedenParishList: () => import("./HerSwedenParishList")
  }
};
</script>
